import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import "./opentimes.scss"

export default function OpenTimes() {
  const { sanityTimesSettings: data } = useStaticQuery(graphql`
    query TimesSettingsQuery {
      sanityTimesSettings(_id: { eq: "timesSettings" }) {
        heading
        times {
          days
          times
        }
      }
    }
  `)
  return (
    <div className="open-times">
      {data.times.map(time => (
        <div key={time.days} className="entry">
          <div className="day">{time.days}</div>
          <div
            className={`time ${(time.times === "Ruhetag" ||
              time.times === "Geschlossen") &&
              "text-danger"}`}
          >
            {time.times}
          </div>
        </div>
      ))}
    </div>
  )
}
