import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./speisekarte.scss"
import BlockContent from "../components/block-content"
import OpenTimes from "../components/opentimes"

const SpeisekartePage = ({ data: { sanityMenuPageSettings: data } }) => {
  return (
    <Layout page="speisekarte" noAlerts>
      <SEO title={data.title} />
      <div className="display-header">
        <h2>{data.heading}</h2>
      </div>
      <div>
        <BlockContent blocks={data._rawBody} />
      </div>
      <div>
        <OpenTimes />
      </div>
      <hr />
      <div className="links">
        {data.files.map(file => (
          <div className="link">
            <a className="open-pdf" href={`${file.asset.url}?dl`}>
              <i className="fas fa-book-open"></i>
              <span>{file.title}</span>
            </a>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query MenuPageSettingsQuery {
    sanityMenuPageSettings(_id: { eq: "menuPageSettings" }) {
      _rawBody
      heading
      title
      files {
        title
        asset {
          url
        }
      }
    }
  }
`

export default SpeisekartePage
